import React, { useMemo, useReducer, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";

import { api, MOVIES_URL } from "helpers/api";

import reducer, {
  initialState,
  MOVIE_INVALID,
  MOVIE_LOADING,
  MOVIE_SAVING,
  MOVIE_SUCCESS,
  MOVIE_FAILURE,
  MOVIE_FAILURE_AFTER_SAVING,
  ADD_MOVIE_TAG,
  DELETE_MOVIE_TAG,
} from "../reducers/movie";

const MovieContext = React.createContext();

function MovieProvider({ children }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (id && id !== "new") {
      getMovie(id);
    }
    return () => dispatch({ type: MOVIE_INVALID });
  }, [id]);

  const getMovie = async (id) => {
    try {
      dispatch({ type: MOVIE_LOADING });
      const data = await api.get(`${MOVIES_URL}/${id}`);

      dispatch({ type: MOVIE_SUCCESS, payload: { data } });
    } catch (error) {
      notification.error({
        message: "Wystąpił błąd podczas pobierania filmu",
      });
      dispatch({ type: MOVIE_FAILURE, payload: { error } });
    }
  };

  const createMovie = async ({ title, user_id, tags, description, file }) => {
    try {
      dispatch({ type: MOVIE_SAVING });

      const { _id } = await api.post(`${MOVIES_URL}`, { title, user_id, tags, description, file });

      notification.success({
        message: "Film został dodany",
      });

      navigate(`/movies/${_id}`);
    } catch (error) {
      dispatch({ type: MOVIE_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas dodawania filmu",
      });
    }
  };

  const updateMovie = async ({ title, source_url, description }) => {
    try {
      dispatch({ type: MOVIE_SAVING });
      const data = await api.put(`${MOVIES_URL}/${id}`, {
        title,
        source_url,
        description,
      });

      dispatch({ type: MOVIE_SUCCESS, payload: { data } });
      notification.success({
        message: "Film został zaktualizowany",
      });
    } catch (error) {
      dispatch({ type: MOVIE_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas aktualizowania filmu",
      });
    }
  };

  const deleteMovie = () =>
    new Promise(async (resolve, reject) => {
      try {
        await api.delete(`${MOVIES_URL}/${id}`);
        notification.success({
          message: "Film został usunięty",
        });
        resolve();
        navigate("/movies");
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas usuwania filmu",
        });
        reject();
      }
    });

  const createMovieTag = (tag_id) =>
    new Promise(async (resolve, reject) => {
      try {
        const data = await api.post(`${MOVIES_URL}/${id}/tags`, { tag_id });
        notification.success({
          message: "Tag filmu został dodany",
        });
        resolve();
        dispatch({ type: ADD_MOVIE_TAG, payload: { data } });
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas dodawania taga",
        });
        reject();
      }
    });

  const deleteMovieTag = (tag_id) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: MOVIE_SAVING });
        const { _id } = await api.delete(`${MOVIES_URL}/${id}/tags/${tag_id}`);
        notification.success({
          message: "Tag filmu został usunięty",
        });
        resolve();
        dispatch({ type: DELETE_MOVIE_TAG, payload: { _id } });
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas usuwania filmu",
        });
        dispatch({ type: MOVIE_FAILURE_AFTER_SAVING });
        reject();
      }
    });

  const value = useMemo(() => {
    return {
      state,
      updateMovie,
      deleteMovie,
      createMovie,
      createMovieTag,
      deleteMovieTag,
    };
  }, [state]);

  return (
    <MovieContext.Provider value={value}>{children}</MovieContext.Provider>
  );
}

const useMovie = () => useContext(MovieContext);
export { MovieContext, useMovie };
export default MovieProvider;
