export const SET_LOGGED = "SET_LOGGED";
export const SET_LOADING = "SET_LOADING";
export const SET_USER = "SET_USER";

export const initialState = {
  is_logged: false,
  is_loading: true,
  user: null
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOGGED: {
      return {
        ...state,
        is_logged: payload.is_logged,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        is_loading: payload.is_loading,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: payload.user,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
