import React from "react";
import { Card, Row, Col } from "antd";

import { useAdvertise } from "context/providers/advertise";

import PageLoader from "components/PageLoader";
import PageFailure from "components/PageFailure";

import AdvertiseEditForm from "./components/AdvertiseEditForm";
import TagsList from "./components/TagsList";

const AdvertiseContainer = () => {
  const {
    state: { status, data },
  } = useAdvertise();
  if (status === "invalid" || status === "loading") {
    return <PageLoader />;
  }
  if (status === "failure") {
    return (
      <PageFailure
        path="/advertises"
        title="Nie udało się pobrać reklamy."
        button_text="Wróć do listy reklam"
      />
    );
  }
  return (
    <Row gutter={{ lg: 16 }}>
      <Col span="18">
        <AdvertiseEditForm />
        <TagsList />
      </Col>
      <Col span="6">
        <Card>
          <video width="100%" controls>
            <source src={data?.urls?.hd} type="video/mp4" />
          </video>
        </Card>
      </Col>
    </Row>
  );
};

export default AdvertiseContainer;
