import convertDate from "helpers/convertDate";

const columns = [
  {
    title: "Nazwa",
    dataIndex: "name",
    sorter: true,
  },
  {
    title: "Data utworzenia",
    dataIndex: "created_at",
    sorter: true,
    render: (date) => convertDate(date),
  },
];

export default columns;
