import React, {
  useMemo,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { useCookies } from "react-cookie";
import { notification } from "antd";
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import {
  api,
  createAxiosAuthorization,
  removeAxiosAuthorization,
  AUTH_URL,
  CURRENT_USER_URL,
} from "helpers/api";

import reducer, {
  initialState,
  SET_LOGGED,
  SET_LOADING,
  SET_USER,
} from "../reducers/auth";

import Loader from "components/Loader";
import LoginPanel from "components/LoginPanel";
import Main from "components/Main";
import NotFound from "views/404";

const AuthContext = React.createContext();

function AuthProvider() {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(
    reducer,
    initialState
  );
  const [{ auth_token }, setCookie, removeCookie] =
    useCookies(["auth_token"]);

  useEffect(() => {
    if (Boolean(auth_token)) {
      checkAuthorization();
    } else {
      setLogged(false);
      setLoading(false);
    }
  }, []);

  const setLoading = (is_loading) =>
    dispatch({
      type: SET_LOADING,
      payload: { is_loading },
    });

  const setLogged = (is_logged) =>
    dispatch({ type: SET_LOGGED, payload: { is_logged } });

  const setUser = (user) =>
    dispatch({ type: SET_USER, payload: { user } });

  const checkAuthorization = async () => {
    try {
      const data = await api.get(CURRENT_USER_URL, {
        headers: { Authorization: `Bearer ${auth_token}` },
      });
      if (data.user_type !== "admin") {
        removeCookie("auth_token");
        throw new Error("Brak uprawnień");
      }
      createAxiosAuthorization(auth_token);
      setUser(data);
      setLogged(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      removeCookie("auth_token");
      notification.error({
        message: error?.message || "Błąd autoryzacji",
      });
      setLogged(false);
      setLoading(false);
    }
  };

  const handleLogin = (values) =>
    new Promise(async (resolve, reject) => {
      try {
        const { token, ...user } = await api.post(
          AUTH_URL,
          values
        );
        setCookie("auth_token", token, {
          path: "/",
          maxAge: 7 * 24 * 3600,
        });
        createAxiosAuthorization(token);
        setUser(user);
        setLogged(true);
        setLoading(false);
        navigate("/");
        resolve();
      } catch (error) {
        reject(error);
      }
    });

  const handleLogout = () => {
    removeAxiosAuthorization();
    removeCookie("auth_token");
    setLogged(false);
    setUser(null);
    notification.success({
      message: "Wylogowano pomyślnie",
    });
  };

  const value = useMemo(() => {
    return {
      state,
      handleLogin,
      handleLogout,
    };
  }, [state]);

  if (state.is_loading) {
    return <Loader />;
  }
  return (
    <AuthContext.Provider value={value}>
      {!state.is_logged ? (
        <Routes>
          <Route
            path="/unpiumpulaterecintocracer"
            element={<LoginPanel />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      ) : (
        <Main />
      )}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);
export { AuthContext, useAuth };
export default AuthProvider;
