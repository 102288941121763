export const RED_GIFS_MOVIE_INVALID = "RED_GIFS_MOVIE_INVALID";
export const RED_GIFS_MOVIE_LOADING = "RED_GIFS_MOVIE_LOADING";
export const RED_GIFS_MOVIE_SAVING = "RED_GIFS_MOVIE_SAVING";
export const RED_GIFS_MOVIE_SUCCESS = "RED_GIFS_MOVIE_SUCCESS";
export const RED_GIFS_MOVIE_FAILURE = "RED_GIFS_MOVIE_FAILURE";
export const RED_GIFS_MOVIE_FAILURE_AFTER_SAVING = "RED_GIFS_MOVIE_FAILURE_AFTER_SAVING";

export const initialState = {
  status: "invalid",
  data: null,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RED_GIFS_MOVIE_INVALID: {
      return initialState;
    }
    case RED_GIFS_MOVIE_LOADING: {
      return {
        ...state,
        status: "loading",
      };
    }
    case RED_GIFS_MOVIE_SAVING: {
      return {
        ...state,
        status: "saving",
      };
    }
    case RED_GIFS_MOVIE_SUCCESS: {
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    }
    case RED_GIFS_MOVIE_FAILURE: {
      return {
        ...state,
        status: "failure",
      };
    }
    case RED_GIFS_MOVIE_FAILURE_AFTER_SAVING: {
      return {
        ...state,
        status: "error_after_saving",
      };
    }
    default:
      return state;
  }
};

export default userReducer;
