import React, {
  useMemo,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";

import { api, ADVERTISES_URL } from "helpers/api";

import reducer, {
  initialState,
  ADVERTISE_INVALID,
  ADVERTISE_LOADING,
  ADVERTISE_SAVING,
  ADVERTISE_SUCCESS,
  ADVERTISE_FAILURE,
  ADVERTISE_FAILURE_AFTER_SAVING,
  ADD_ADVERTISE_TAG,
  DELETE_ADVERTISE_TAG,
} from "../reducers/advertise";

const AdvertiseContext = React.createContext();

function AdvertiseProvider({ children }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    if (id && id !== "new") {
      getAdvertise(id);
    }
    return () => dispatch({ type: ADVERTISE_INVALID });
  }, [id]);

  const getAdvertise = async (id) => {
    try {
      dispatch({ type: ADVERTISE_LOADING });
      const data = await api.get(`${ADVERTISES_URL}/${id}`);

      dispatch({
        type: ADVERTISE_SUCCESS,
        payload: { data },
      });
    } catch (error) {
      notification.error({
        message: "Wystąpił błąd podczas pobierania reklamy",
      });
      dispatch({
        type: ADVERTISE_FAILURE,
        payload: { error },
      });
    }
  };

  const createAdvertise = async ({
    is_active,
    advertise_url,
    user_id,
    tags,
    description,
    file,
  }) => {
    try {
      dispatch({ type: ADVERTISE_SAVING });
      const { _id } = await api.post(`${ADVERTISES_URL}`, {
        is_active,
        advertise_url,
        user_id,
        tags,
        description,
        file,
      });

      notification.success({
        message: "Reklama została dodana",
      });
      navigate(`/advertises/${_id}`);
    } catch (error) {
      dispatch({ type: ADVERTISE_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas dodawania reklamy",
      });
    }
  };

  const updateAdvertise = async ({
    advertise_url,
    description,
    is_active,
    user_id,
  }) => {
    try {
      dispatch({ type: ADVERTISE_SAVING });
      const data = await api.put(
        `${ADVERTISES_URL}/${id}`,
        { advertise_url, description, is_active, user_id }
      );

      dispatch({
        type: ADVERTISE_SUCCESS,
        payload: { data },
      });
      notification.success({
        message: "Reklama została zaktualizowana",
      });
    } catch (error) {
      dispatch({ type: ADVERTISE_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas aktualizowania reklamy",
      });
    }
  };

  const deleteAdvertise = () =>
    new Promise(async (resolve, reject) => {
      try {
        await api.delete(`${ADVERTISES_URL}/${id}`);
        notification.success({
          message: "Reklama została usunięta",
        });
        resolve();
        navigate("/advertises");
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas usuwania reklamy",
        });
        reject();
      }
    });

    const createAdvertiseTag = (tag_id) =>
    new Promise(async (resolve, reject) => {
      try {
        const data = await api.post(`${ADVERTISES_URL}/${id}/tags`, { tag_id });
        notification.success({
          message: "Tag reklamy został dodany",
        });
        resolve();
        dispatch({ type: ADD_ADVERTISE_TAG, payload: { data } });
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas dodawania taga",
        });
        reject();
      }
    });

  const deleteAdvertiseTag = (tag_id) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: ADVERTISE_SAVING });
        const { _id } = await api.delete(`${ADVERTISES_URL}/${id}/tags/${tag_id}`);
        notification.success({
          message: "Tag reklamy został usunięty",
        });
        resolve();
        dispatch({ type: DELETE_ADVERTISE_TAG, payload: { _id } });
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas usuwania taga",
        });
        dispatch({ type: ADVERTISE_FAILURE_AFTER_SAVING });
        reject();
      }
    });

  const value = useMemo(() => {
    return {
      state,
      updateAdvertise,
      deleteAdvertise,
      createAdvertise,
      createAdvertiseTag,
      deleteAdvertiseTag
    };
  }, [state]);

  return (
    <AdvertiseContext.Provider value={value}>
      {children}
    </AdvertiseContext.Provider>
  );
}

const useAdvertise = () => useContext(AdvertiseContext);
export { AdvertiseContext, useAdvertise };
export default AdvertiseProvider;
