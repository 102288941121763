import "./styles.scss";

import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Layout, Menu } from "antd";

import {
  AreaChartOutlined,
  UserOutlined,
  VideoCameraOutlined,
  TagOutlined,
  TrademarkOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const nav = [
  {
    icon: <AreaChartOutlined />,
    label: "Dashboard",
    path: "/",
  },
  {
    icon: <UserOutlined />,
    label: "Użytkownicy",
    path: "/users",
  },
  {
    icon: <VideoCameraOutlined />,
    label: "Filmy",
    path: "/movies",
  },
  {
    icon: <TagOutlined />,
    label: "Tagi",
    path: "/tags",
  },
  {
    icon: <VideoCameraOutlined />,
    label: "RedGifs",
    path: "/red_gifs_movies",
  },
  {
    icon: <TrademarkOutlined />,
    label: "Reklamy",
    path: "/advertises",
  },
  {
    icon: <SettingOutlined />,
    label: "Ustawienia",
    path: "/settings",
  },
];

const Sidebar = () => {
  const navigate = useNavigate();

  return (
    <Layout.Sider className="sidebar-wrapper">
      <div className="sidebar-wrapper">
        <div className="sidebar-title">
          <Typography.Title
            level={2}
            style={{ color: "#fff", margin: 0 }}
          >
            XIKFAP
          </Typography.Title>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          items={nav.map((item) => ({
            ...item,
            onClick: () => navigate(item.path),
          }))}
        />
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;
