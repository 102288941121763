import axios from "axios";

export const baseURL = "https://api.xikfap.com/v1/admin";

export const api = axios.create({ baseURL });

api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (error.response.status === 401) {
      removeAxiosAuthorization();
      document.cookie =
        "auth_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export const createAxiosAuthorization = (token) => {
  api.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${token}`;
};

export const removeAxiosAuthorization = () => {
  delete api.defaults.headers.common["Authorization"];
};

export const AUTH_URL = "/auth";
export const CURRENT_USER_URL = `/me`;
export const USERS_URL = "/users";
export const TAGS_URL = "/tags";
export const MOVIES_URL = "/movies";
export const RED_GIFS_MOVIES_URL = "/red_gifs_movies";
export const STATISTICS_URL = "/statistics";
export const ADVERTISES_URL = "/cams";
export const SETTINGS_URL = "/config";

export const UPLOAD_URL =
  "https://upload.jupiter.xikfap.com/upload";
