export const SETTINGS_INVALID = "SETTINGS_INVALID";
export const SETTINGS_LOADING = "SETTINGS_LOADING";
export const SETTINGS_SAVING = "SETTINGS_SAVING";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";
export const SETTINGS_FAILURE = "SETTINGS_FAILURE";
export const SETTINGS_FAILURE_AFTER_SAVING = "SETTINGS_FAILURE_AFTER_SAVING";

export const initialState = {
  status: "invalid",
  data: null,
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SETTINGS_INVALID: {
      return initialState;
    }
    case SETTINGS_LOADING: {
      return {
        ...state,
        status: "loading",
      };
    }
    case SETTINGS_SAVING: {
      return {
        ...state,
        status: "saving",
      };
    }
    case SETTINGS_SUCCESS: {
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    }
    case SETTINGS_FAILURE: {
      return {
        ...state,
        status: "failure",
      };
    }
    case SETTINGS_FAILURE_AFTER_SAVING: {
      return {
        ...state,
        status: "error_after_saving",
      };
    }

    default:
      return state;
  }
};

export default settingsReducer;
