import React from "react";
import { Card, Row, Col } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { useMovie } from "context/providers/movie";
import convertDate from "helpers/convertDate";

import PageLoader from "components/PageLoader";
import PageFailure from "components/PageFailure";

import MovieEditForm from "./components/MovieEditForm";
import MovieInfo from "./components/MovieInfo";
import TagsList from "./components/TagsList";

const MovieContainer = () => {
  const {
    state: { status, data },
  } = useMovie();
  if (status === "invalid" || status === "loading") {
    return <PageLoader />;
  }
  if (status === "failure") {
    return (
      <PageFailure
        path="/movies"
        title="Nie udało się pobrać filmu."
        button_text="Wróć do listy filmów"
      />
    );
  }
  return (
    <Row gutter={{ lg: 16 }}>
      <Col span="18">
        <MovieInfo />
        <MovieEditForm />
        <TagsList />
      </Col>
      <Col span="6">
        <Card
          actions={[
            <Link to={`/users/${data?.user?.id}`}>
              <EditOutlined key="edit" />,
            </Link>,
          ]}
          title={data?.user?.nickname}
          hoverable
          className="m-b-15"
        >
          <p>E-mail: {data?.user?.email}</p>
          <p>Ostatnie logowanie: {convertDate(data?.user?.last_logged_at)}</p>
          <p>Typ: {data?.user?.user_type}</p>
        </Card>
        <Card>
          <video width="100%" controls>
            <source
              src={data?.urls?.hd}
              type="video/mp4"
            />
          </video>
        </Card>
      </Col>
    </Row>
  );
};

export default MovieContainer;
