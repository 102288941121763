import React from "react";
import TagProvider from "context/providers/tag";
import TagCreateForm from "./components/TagCreateForm";

const TagCreate = () => (
  <TagProvider>
    <TagCreateForm />
  </TagProvider>
);

export default TagCreate;
