import React from "react";
import {
  notification,
  Button,
  Pagination,
  Form,
  Input,
  Row,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useQueryParams, NumberParam, StringParam } from "use-query-params";
import { useQuery } from "@tanstack/react-query";
import { api, USERS_URL } from "helpers/api";
import { stringify } from "query-string";
import columns from "./const/columns";

const UsersList = () => {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    page_size: NumberParam,
    sort_column: StringParam,
    sort_order: StringParam,
    email: StringParam,
    nickname: StringParam,
  });
  const query_string = stringify(query);
  const navigate = useNavigate();
  const { isLoading: is_loading, data } = useQuery(
    ["users", query],
    () => api.get(`${USERS_URL}?${query_string}`),
    {
      retry: false,
      keepPreviousData: true,
      onError: (err) =>
        notification.error({
          message: err?.response?.data?.message || "Wystąpił błąd",
        }),
    }
  );
  const onTableDataChange = (pagination, filters, { field, order }) => {
    if (Boolean(order)) {
      setQuery({
        ...query,
        page: undefined,
        sort_order: order,
        sort_column: field,
      });
    } else {
      setQuery({
        ...query,
        page: undefined,
        sort_column: undefined,
        sort_order: undefined,
      });
    }
  };
  const [form] = Form.useForm();

  const onFilter = async (values) =>
    setQuery({
      ...query,
      page: undefined,
      sort_column: undefined,
      sort_order: undefined,
      email: values.email,
      nickname: values.nickname,
    });

  const onReset = async () => {
    form.resetFields();
    setQuery({
      ...query,
      page: undefined,
      email: undefined,
      nickname: undefined,
    });
  };

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ paddingBottom: "20px" }}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            email: query?.email || "",
            nickname: query?.nickname || "",
          }}
          onFinish={onFilter}
          autoComplete="off"
          layout="inline"
        >
          <Form.Item label="E-mail" name="email">
            <Input />
          </Form.Item>
          <Form.Item label="Nick" name="nickname">
            <Input />
          </Form.Item>
          <Button loading={is_loading} type="primary" htmlType="submit">
            Filtruj
          </Button>
          <Button
            className="m-l-20"
            type="danger"
            htmlType="button"
            onClick={onReset}
          >
            Resetuj
          </Button>
        </Form>
        <Button onClick={() => navigate("/users/new")}>Dodaj użytkownika</Button>
      </Row>
      <Table
        loading={is_loading}
        columns={columns}
        total={data?.meta?.limit}
        dataSource={data?.data?.map((item) => ({ ...item, key: item._id }))}
        onChange={onTableDataChange}
        pagination={false}
        onRow={({ _id }) => ({ onClick: () => navigate(`/users/${_id}`) })}
      />
      <Row justify="center" align="middle" style={{ padding: "20px 0" }}>
        <Pagination
          {...{
            current: query?.page || 1,
            defaultPageSize: 15,
            pageSize: query?.page_size || 15,
            total: data?.meta?.totalDocs,
            pageSizeOptions: [15, 30, 50],
            showSizeChanger: true,
            onChange: (page, page_size) =>
              setQuery({ ...query, page, page_size }),
          }}
        />
      </Row>
    </>
  );
};

export default UsersList;
