export const MOVIE_INVALID = "MOVIE_INVALID";
export const MOVIE_LOADING = "MOVIE_LOADING";
export const MOVIE_SAVING = "MOVIE_SAVING";
export const MOVIE_SUCCESS = "MOVIE_SUCCESS";
export const MOVIE_FAILURE = "MOVIE_FAILURE";
export const MOVIE_FAILURE_AFTER_SAVING = "MOVIE_FAILURE_AFTER_SAVING";
export const ADD_MOVIE_TAG = "ADD_MOVIE_TAG";
export const DELETE_MOVIE_TAG = "DELETE_MOVIE_TAG";

export const initialState = {
  status: "invalid",
  data: null,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case MOVIE_INVALID: {
      return initialState;
    }
    case MOVIE_LOADING: {
      return {
        ...state,
        status: "loading",
      };
    }
    case MOVIE_SAVING: {
      return {
        ...state,
        status: "saving",
      };
    }
    case MOVIE_SUCCESS: {
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    }
    case MOVIE_FAILURE: {
      return {
        ...state,
        status: "failure",
      };
    }
    case MOVIE_FAILURE_AFTER_SAVING: {
      return {
        ...state,
        status: "error_after_saving",
      };
    }
    case ADD_MOVIE_TAG: {
      return {
        ...state,
        data: {
          ...state.data,
          tags: [...state.data.tags, payload.data]
        },
      };
    }
    case DELETE_MOVIE_TAG: {
      return {
        ...state,
        status: "success",
        data: {
          ...state.data,
          tags: state.data.tags.filter(({ _id }) => _id !== payload._id),
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
