import React from "react";
import {
  notification,
  Button,
  Pagination,
  Form,
  Input,
  Row,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  useQueryParams,
  NumberParam,
  StringParam,
} from "use-query-params";
import { useQuery } from "@tanstack/react-query";
import { api, MOVIES_URL } from "helpers/api";
import { stringify } from "query-string";
import columns from "./const/columns";

const MoviesList = () => {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    page_size: NumberParam,
    sort_column: StringParam,
    sort_order: StringParam,
    title: StringParam,
    user: StringParam,
  });
  const query_string = stringify(query);
  const navigate = useNavigate();
  const { isLoading: is_loading, data } = useQuery(
    ["movies", query],
    () => api.get(`${MOVIES_URL}?${query_string}`),
    {
      retry: false,
      keepPreviousData: true,
      onError: (err) =>
        notification.error({
          message:
            err?.response?.data?.message || "Wystąpił błąd",
        }),
    }
  );

  const onTableDataChange = (
    pagination,
    filters,
    { field, order }
  ) => {
    if (Boolean(order)) {
      setQuery({
        ...query,
        page: undefined,
        sort_order: order,
        sort_column: field,
      });
    } else {
      setQuery({
        ...query,
        page: undefined,
        sort_column: undefined,
        sort_order: undefined,
      });
    }
  };
  const [form] = Form.useForm();

  const onFilter = async (values) =>
    setQuery({
      ...query,
      page: undefined,
      sort_column: undefined,
      sort_order: undefined,
      title: values?.title || undefined,
      user: values?.user || undefined,
    });

  const onReset = async () => {
    form.resetFields();
    setQuery({
      ...query,
      page: undefined,
      title: undefined,
      user: undefined,
    });
  };

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ paddingBottom: "20px" }}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            title: query?.title || "",
            user: query?.user || "",
          }}
          onFinish={onFilter}
          autoComplete="off"
          layout="inline"
        >
          <Form.Item label="Tytuł" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Użytkownik" name="user">
            <Input />
          </Form.Item>
          <Button
            loading={is_loading}
            type="primary"
            htmlType="submit"
          >
            Filtruj
          </Button>
          <Button
            className="m-l-20"
            type="danger"
            htmlType="button"
            onClick={onReset}
          >
            Resetuj
          </Button>
        </Form>
        <Button onClick={() => navigate("/movies/new")}>
          Dodaj film
        </Button>
      </Row>
      <Table
        loading={is_loading}
        columns={columns}
        total={data?.meta?.limit}
        dataSource={data?.data?.map((item) => ({
          ...item,
          key: item._id,
        }))}
        onChange={onTableDataChange}
        pagination={false}
        onRow={({ _id }) => ({
          onClick: () => navigate(`/movies/${_id}`),
        })}
      />
      <Row
        justify="center"
        align="middle"
        style={{ padding: "20px 0" }}
      >
        <Pagination
          {...{
            current: query?.page || 1,
            defaultPageSize: 15,
            pageSize: query?.page_size || 15,
            total: data?.meta?.totalDocs,
            pageSizeOptions: [15, 30, 50],
            showSizeChanger: true,
            onChange: (page, page_size) =>
              setQuery({ ...query, page, page_size }),
          }}
        />
      </Row>
    </>
  );
};

export default MoviesList;
