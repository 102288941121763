import convertDate from "helpers/convertDate";

const columns = [
  {
    title: "Url",
    dataIndex: "advertise_url",
    sorter: true,
  },
  {
    title: "Opis",
    dataIndex: "description",
    sorter: true,
    width: 500,
  },
  {
    title: "Data utworzenia",
    dataIndex: "created_at",
    width: 240,
    sorter: true,
    render: (date) => convertDate(date),
  },
];

export default columns;
