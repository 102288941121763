import convertDate from "helpers/convertDate";

const columns = [
  {
    title: "E-mail",
    dataIndex: "email",
    sorter: true,
  },
  {
    title: "Nick",
    dataIndex: "nickname",
    sorter: true,
  },
  {
    title: "IP",
    dataIndex: "ip_address",
  },
  {
    title: "Typ usera",
    dataIndex: "user_type",
    sorter: true,
  },
  {
    title: "Ilość followersów",
    dataIndex: "following_users_count",
    sorter: true,
  },
  {
    title: "Ostatnie logowanie",
    dataIndex: "last_logged_at",
    sorter: true,
    render: (date) => convertDate(date),
  },
  {
    title: "Data rejestracji",
    dataIndex: "created_at",
    sorter: true,
    render: (date) => convertDate(date),
  },
];

export default columns;
