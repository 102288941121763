import React, { useState } from "react";
import {
  List,
  Card,
  Skeleton,
  Popconfirm,
  Button,
} from "antd";
import { useAdvertise } from "context/providers/advertise";
import AddTagModal from "./components/AddTagModal";

const TagsList = () => {
  const {
    state: { status, data },
    deleteAdvertiseTag,
  } = useAdvertise();
  const [is_open, setOpen] = useState(false);

  return (
    <Card
      className="m-t-15"
      title={`Tagi (${data?.tags?.length || 0})`}
      extra={
        <Button
          type="primary"
          onClick={() => setOpen(true)}
        >
          Dodaj
        </Button>
      }
    >
      <AddTagModal
        is_open={is_open}
        handleClose={() => setOpen(false)}
      />
      <List
        loading={
          status === "invalid" || status === "loading"
        }
        itemLayout="horizontal"
        dataSource={data?.tags || []}
        renderItem={({ _id, name }) => (
          <List.Item
            actions={[
              <Popconfirm
                title={`Jesteś pewien, że chcesz usunąć taga "${name}" dla tej reklamy?`}
                onConfirm={() => deleteAdvertiseTag(_id)}
                cancelText="Nie"
                okText="Tak"
              >
                <Button
                  danger
                  loading={status === "saving"}
                  type="primary"
                  htmlType="submit"
                >
                  Usuń
                </Button>
              </Popconfirm>,
            ]}
          >
            <Skeleton
              avatar
              title={false}
              loading={
                status === "invalid" || status === "loading"
              }
              active
            >
              <List.Item.Meta
                title={name}
                description={_id}
              />
            </Skeleton>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default TagsList;
