import React, {
  useMemo,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { notification } from "antd";

import { api, SETTINGS_URL } from "helpers/api";

import reducer, {
  initialState,
  SETTINGS_LOADING,
  SETTINGS_SAVING,
  SETTINGS_SUCCESS,
  SETTINGS_FAILURE,
  SETTINGS_FAILURE_AFTER_SAVING,
} from "../reducers/settings";

const SettingsContext = React.createContext();

function SettingsProvider({ children }) {
  const [state, dispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = async () => {
    try {
      dispatch({ type: SETTINGS_LOADING });
      const data = await api.get(SETTINGS_URL);

      dispatch({
        type: SETTINGS_SUCCESS,
        payload: { data },
      });
    } catch (error) {
      notification.error({
        message:
          "Wystąpił błąd podczas pobierania ustawień",
      });
      dispatch({
        type: SETTINGS_FAILURE,
        payload: { error },
      });
    }
  };

  const updateSettings = async ({
    random_advertise_url,
    random_advertises_frequency,
    advertises_frequency,
    adds_enabled,
  }) => {
    try {
      dispatch({ type: SETTINGS_SAVING });
      const data = await api.put(SETTINGS_URL, {
        random_advertise_url,
        random_advertises_frequency,
        advertises_frequency,
        adds_enabled,
      });

      dispatch({
        type: SETTINGS_SUCCESS,
        payload: { data },
      });
      notification.success({
        message: "Ustawienia zostały zaktualizowane",
      });
    } catch (error) {
      dispatch({ type: SETTINGS_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas aktualizowania ustawień",
      });
    }
  };

  const value = useMemo(() => {
    return {
      state,
      updateSettings,
    };
  }, [state]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

const useSettings = () => useContext(SettingsContext);
export { SettingsContext, useSettings };
export default SettingsProvider;
