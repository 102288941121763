import { Layout } from "antd";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Router from "./components/Router";

const { Content } = Layout;

const Main = () => {
  return (
    <Layout hasSider>
      <Sidebar />
      <Layout
        className="site-layout"
        style={{
          marginLeft: 200,
          minHeight: "100vh",
        }}
      >
        <Header />
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <Router />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Main;
