export const ADVERTISE_INVALID = "ADVERTISE_INVALID";
export const ADVERTISE_LOADING = "ADVERTISE_LOADING";
export const ADVERTISE_SAVING = "ADVERTISE_SAVING";
export const ADVERTISE_SUCCESS = "ADVERTISE_SUCCESS";
export const ADVERTISE_FAILURE = "ADVERTISE_FAILURE";
export const ADVERTISE_FAILURE_AFTER_SAVING = "ADVERTISE_FAILURE_AFTER_SAVING";
export const ADD_ADVERTISE_TAG = "ADD_ADVERTISE_TAG";
export const DELETE_ADVERTISE_TAG = "DELETE_ADVERTISE_TAG";

export const initialState = {
  status: "invalid",
  data: null,
};

const advertiseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADVERTISE_INVALID: {
      return initialState;
    }
    case ADVERTISE_LOADING: {
      return {
        ...state,
        status: "loading",
      };
    }
    case ADVERTISE_SAVING: {
      return {
        ...state,
        status: "saving",
      };
    }
    case ADVERTISE_SUCCESS: {
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    }
    case ADVERTISE_FAILURE: {
      return {
        ...state,
        status: "failure",
      };
    }
    case ADVERTISE_FAILURE_AFTER_SAVING: {
      return {
        ...state,
        status: "error_after_saving",
      };
    }
    case ADD_ADVERTISE_TAG: {
      return {
        ...state,
        data: {
          ...state.data,
          tags: [...state.data.tags, payload.data]
        },
      };
    }
    case DELETE_ADVERTISE_TAG: {
      return {
        ...state,
        status: "success",
        data: {
          ...state.data,
          tags: state.data.tags.filter(({ _id }) => _id !== payload._id),
        },
      };
    }
    default:
      return state;
  }
};

export default advertiseReducer;
