export const TAG_INVALID = "TAG_INVALID";
export const TAG_LOADING = "TAG_LOADING";
export const TAG_SAVING = "TAG_SAVING";
export const TAG_SUCCESS = "TAG_SUCCESS";
export const TAG_FAILURE = "TAG_FAILURE";
export const TAG_FAILURE_AFTER_SAVING = "TAG_FAILURE_AFTER_SAVING";

export const initialState = {
  status: "invalid",
  data: null,
};

const tagReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TAG_INVALID: {
      return initialState;
    }
    case TAG_LOADING: {
      return {
        ...state,
        status: "loading",
      };
    }
    case TAG_SAVING: {
      return {
        ...state,
        status: "saving",
      };
    }
    case TAG_SUCCESS: {
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    }
    case TAG_FAILURE: {
      return {
        ...state,
        status: "failure",
      };
    }
    case TAG_FAILURE_AFTER_SAVING: {
      return {
        ...state,
        status: "error_after_saving",
      };
    }

    default:
      return state;
  }
};

export default tagReducer;
