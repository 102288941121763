import React from "react";
import { Card, Button, Form, Input, Row, Col } from "antd";

import { useRedGifsMovie } from "context/providers/red_gifs_movie";

const RedGifsMovieCreateForm = () => {
  const {
    state: { status },
    createRedGifsMovie,
  } = useRedGifsMovie();

  const onFinish = (values) => createRedGifsMovie(values);

  return (
    <Row>
      <Col span="8">
        <Card>
          <Form
            name="movie_create"
            initialValues={{
              red_gifs_id: "",
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item label="RedGifsID" name="red_gifs_id">
              <Input />
            </Form.Item>
            <Row>
              <Button
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zapisz
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default RedGifsMovieCreateForm;
