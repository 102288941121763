import React from "react";
import {
  Card,
  Button,
  Form,
  Input,
  Row,
  Col,
  Checkbox,
} from "antd";

import { useSettings } from "context/providers/settings";

import PageLoader from "components/PageLoader";
import PageFailure from "components/PageFailure";

const SettingsEditForm = () => {
  const {
    state: { status, data },
    updateSettings,
  } = useSettings();

  if (status === "invalid" || status === "loading") {
    return <PageLoader />;
  }
  if (status === "failure") {
    return (
      <PageFailure
        path="/"
        title="Nie udało się pobrać ustawień."
        button_text="Wróć"
      />
    );
  }

  const onFinish = (values) => updateSettings(values);

  return (
    <Row>
      <Col span="8">
        <Card>
          <Form
            name="settings_edit"
            initialValues={{
              random_advertise_url:
                data?.random_advertise_url,
              random_advertises_frequency:
                data?.random_advertises_frequency,
              advertises_frequency:
                data?.advertises_frequency,
              adds_enabled: data?.adds_enabled,
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Link do randomowych reklam video"
              name="random_advertise_url"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Częstotliwość wyświetlania randomowych reklam"
              name="random_advertises_frequency"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Częstotliwość wyświetlania reklam z kamerek"
              name="advertises_frequency"
            >
              <Input />
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              name="adds_enabled"
            >
              <Checkbox>Reklamy aktywne</Checkbox>
            </Form.Item>
            <Row>
              <Button
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zapisz
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default SettingsEditForm;
