import React from "react";
import { Avatar } from "antd";

import convertDate from "helpers/convertDate";

const columns = [
  {
    title: "Poster",
    dataIndex: "urls",
    sorter: false,
    render: ({ poster }) => (
      <Avatar shape="square" size={40} src={poster} />
    ),
  },
  {
    title: "Tytuł",
    dataIndex: "title",
    sorter: true,
  },
  {
    title: "Czas trwania",
    dataIndex: "duration",
    sorter: true,
  },
  {
    title: "Data rejestracji",
    dataIndex: "created_at",
    sorter: true,
    render: (date) => convertDate(date),
  },
];

export default columns;
