import React from "react";
import RedGifsMovieProvider from "context/providers/red_gifs_movie";
import RedGifsMovieCreateForm from "./components/RedGifsMovieCreateForm";

const RedGifsMovieCreate = () => (
  <RedGifsMovieProvider>
    <RedGifsMovieCreateForm />
  </RedGifsMovieProvider>
);

export default RedGifsMovieCreate;
