import React from "react";
import { Button } from "antd";
import { VideoCameraFilled, DownloadOutlined } from "@ant-design/icons";

import convertDate from "helpers/convertDate";
import DownloadMovie from "../components/DownloadMovie";

const columns = [
  {
    title: "Redgifs ID",
    dataIndex: "red_gifs_id",
    sorter: true,
  },
  {
    title: "Czas trwania",
    dataIndex: "duration",
    sorter: true,
  },
  {
    title: "Tagi",
    dataIndex: "tags",
    width: 400,
    render: (tags) => tags?.map((name) => name).join(", "),
  },
  {
    title: "Użytkownik",
    dataIndex: "user_name",
    sorter: true,
  },
  {
    title: "Data dodania",
    dataIndex: "created_at",
    sorter: true,
    render: (date) => convertDate(date),
  },
  {
    title: "Pobrane",
    dataIndex: "is_scrapped",
    render: (is_scrapped) => (is_scrapped ? "Tak" : "Nie"),
  },
  {
    title: "Usunięte",
    dataIndex: "is_deleted",
    render: (is_deleted) => (is_deleted ? "Tak" : "Nie"),
  },
  {
    title: "Podgląd",
    dataIndex: "source_url",
    render: (source_url) => (
      <Button
        href={source_url}
        target="__blank"
        type="primary"
        shape="circle"
        icon={<VideoCameraFilled />}
      />
    ),
  },
  {
    title: "Pobierz",
    render: ({ is_scrapped, red_gifs_id }) => !is_scrapped ? <DownloadMovie id={red_gifs_id} /> : '-'
  },
];

export default columns;
