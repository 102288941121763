import React from "react";
import { Routes, Route } from "react-router-dom";

import Dashboard from "views/home/Dashboard";

import UsersList from "views/users/List";
import UserEdit from "views/users/Edit";
import UserNew from "views/users/New";

import TagsList from "views/tags/List";
import TagEdit from "views/tags/Edit";
import TagNew from "views/tags/New";

import RedGifsMoviesList from "views/red_gifs_movies/List";
import RedGifsMovieNew from "views/red_gifs_movies/New";

import MoviesList from "views/movies/List";
import MovieEdit from "views/movies/Edit";
import MovieNew from "views/movies/New";

import AdvertisesList from "views/advertises/List";
import AdvertiseEdit from "views/advertises/Edit";
import AdvertiseNew from "views/advertises/New";

import Settings from "views/Settings";

import NotFound from "views/404";

const Router = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Dashboard />} />
      <Route exact path="/settings" element={<Settings />} />

      <Route exact path="/users" element={<UsersList />} />
      <Route
        exact
        path="/users/new"
        element={<UserNew />}
      />
      <Route
        exact
        path="/users/:id"
        element={<UserEdit />}
      />

      <Route exact path="/tags" element={<TagsList />} />
      <Route exact path="/tags/new" element={<TagNew />} />
      <Route exact path="/tags/:id" element={<TagEdit />} />

      <Route
        exact
        path="/red_gifs_movies"
        element={<RedGifsMoviesList />}
      />
      <Route
        exact
        path="/red_gifs_movies/new"
        element={<RedGifsMovieNew />}
      />

      <Route
        exact
        path="/movies"
        element={<MoviesList />}
      />
      <Route
        exact
        path="/movies/new"
        element={<MovieNew />}
      />
      <Route
        exact
        path="/movies/:id"
        element={<MovieEdit />}
      />

      <Route
        exact
        path="/advertises"
        element={<AdvertisesList />}
      />
      <Route
        exact
        path="/advertises/new"
        element={<AdvertiseNew />}
      />
      <Route
        exact
        path="/advertises/:id"
        element={<AdvertiseEdit />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
