import "./styles.scss";

import React, { useState } from "react";
import { Card, Button, Form, Input, notification } from "antd";
import { useAuth } from "context/providers/auth";

const LoginPanel = () => {
  const [is_loading, setLoading] = useState(false);
  const { handleLogin } = useAuth();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await handleLogin(values);
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.message || "Wystąpił błąd";
      notification.error({ message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-panel">
      <Card title="Logowanie" style={{ width: 540 }}>
        <Form
          name="basic"
          initialValues={{
            email: "",
            password: "",
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                message: "Email jest wymagany!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Hasło"
            name="password"
            rules={[
              {
                required: true,
                message: "Hasło jest wymagane!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Button loading={is_loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default LoginPanel;
