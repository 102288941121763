import React from "react";
import {
  Card,
  Button,
  Form,
  Input,
  Row,
  Col,
  Popconfirm,
  Select,
  useForm,
} from "antd";

import { useUser } from "context/providers/user";

import user_type_options from "helpers/user_type_options";

import PageLoader from "components/PageLoader";
import PageFailure from "components/PageFailure";

const UserEditForm = () => {
  const {
    state: { status, data },
    updateUser,
    deleteUser,
    changeUserPassword,
  } = useUser();
  const [change_password_form] = Form.useForm();
  if (status === "invalid" || status === "loading") {
    return <PageLoader />;
  }
  if (status === "failure") {
    return (
      <PageFailure
        path="/users"
        title="Nie udało się pobrać użytkownika."
        button_text="Wróć do listy użytkowników"
      />
    );
  }
  const onFinishUpdateUser = (values) => updateUser(values);
  const onFinishChangePassword = (values) => {
    change_password_form.setFieldValue("password", "");
    changeUserPassword(values);
  };
  return (
    <Row gutter={{ lg: 16 }}>
      <Col span="16">
        <Card>
          <Form
            name="user_edit"
            initialValues={{
              first_name: data?.first_name,
              last_name: data?.last_name,
              nickname: data?.nickname,
              email: data?.email,
              user_type: data?.user_type,
            }}
            onFinish={onFinishUpdateUser}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span="8">
                <Form.Item label="Imię" name="first_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Nazwisko" name="last_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  label="Nick"
                  name="nickname"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span="8">
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  label="Typ użytkownika"
                  name="user_type"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Select style={{ width: 120 }}>
                    {user_type_options?.map(({ label, value }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zapisz
              </Button>

              <Popconfirm
                title={`Jesteś pewien, że chcesz usunąć użytkownika "${data?.nickname}" i wszystkie jego filmy?`}
                onConfirm={deleteUser}
                cancelText="Nie"
                okText="Tak"
              >
                <Button
                  danger
                  loading={status === "saving"}
                  type="primary"
                  htmlType="submit"
                >
                  Usuń
                </Button>
              </Popconfirm>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span="8">
        <Card>
          <Form
            form={change_password_form}
            name="user_change_password"
            initialValues={{
              password: "",
            }}
            onFinish={onFinishChangePassword}
            autoComplete="off"
            layout="vertical"
          >
            <Row>
              <Col span="12">
                <Form.Item
                  label="Hasło"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zmień hasło
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserEditForm;
