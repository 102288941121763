import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import {
  Card,
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Popconfirm,
} from "antd";

import { useAdvertise } from "context/providers/advertise";

import { api, USERS_URL } from "helpers/api";

let timeout;

const AdvertiseEditForm = () => {
  const {
    state: { status, data },
    updateAdvertise,
    deleteAdvertise,
  } = useAdvertise();
  const [user_value, setUserValue] = useState(null);

  const { data: users } = useQuery(
    ["create_tags_users_query", user_value],
    () =>
      api.get(
        `${USERS_URL}${
          user_value ? `?nickname=${user_value}` : ""
        }`
      )
  );

  const users_options =
    users?.data?.map(({ _id, nickname }) => (
      <Select.Option key={_id} value={_id}>
        {nickname}
      </Select.Option>
    )) || [];

  const onSearchUser = (a) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setUserValue(a);
    }, 300);
  };

  const onFinish = (values) => updateAdvertise(values);

  return (
    <Card>
      <Form
        name="movie_edit"
        initialValues={{
          advertise_url: data?.advertise_url,
          description: data?.description,
          is_active: data?.is_active,
          user_id: data?.user?.id,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span="24">
            <Form.Item
              label="Url reklamy"
              name="advertise_url"
              rules={[
                {
                  required: true,
                  message: "Pole wymagane",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span="12">
            <Form.Item
              label="Użytkownik"
              name="user_id"
              rules={[
                {
                  required: true,
                  message: "Pole wymagane",
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Szukaj..."}
                showArrow={true}
                filterOption={false}
                onSearch={onSearchUser}
                notFoundContent={null}
              >
                {[
                  <Select.Option
                    key={data?.user?.id}
                    value={data?.user?.id}
                  >
                    {data?.user?.nickname}
                  </Select.Option>,
                  ...users_options,
                ]}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span="24">
            <Form.Item label="Opis" name="description">
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item
              name="is_active"
              valuePropName="checked"
            >
              <Checkbox>Aktywna</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Button
            className="m-r-20"
            loading={status === "saving"}
            type="primary"
            htmlType="submit"
          >
            Zapisz
          </Button>
          <Popconfirm
            title={`Jesteś pewien?`}
            onConfirm={deleteAdvertise}
            cancelText="Nie"
            okText="Tak"
          >
            <Button
              danger
              loading={status === "saving"}
              type="primary"
              htmlType="submit"
            >
              Usuń
            </Button>
          </Popconfirm>
        </Row>
      </Form>
    </Card>
  );
};

export default AdvertiseEditForm;
