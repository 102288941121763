import React from "react";
import UserProvider from "context/providers/user";
import UserEditForm from "./components/UserEditForm";
import MoviesList from "./components/MoviesList";

const UserEdit = () => (
  <UserProvider>
    <UserEditForm />
    <MoviesList/>
  </UserProvider>
);

export default UserEdit;
