import React, { useMemo, useReducer, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";

import { api, RED_GIFS_MOVIES_URL } from "helpers/api";

import reducer, {
  initialState,
  RED_GIFS_MOVIE_INVALID,
  RED_GIFS_MOVIE_LOADING,
  RED_GIFS_MOVIE_SAVING,
  RED_GIFS_MOVIE_SUCCESS,
  RED_GIFS_MOVIE_FAILURE,
  RED_GIFS_MOVIE_FAILURE_AFTER_SAVING,
} from "../reducers/red_gifs_movie";

const RedGifsMovieContext = React.createContext();

function RedGifsMovieProvider({ children }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (id && id !== "new") {
      getRedGifsRedGifsMovie(id);
    }
    return () => dispatch({ type: RED_GIFS_MOVIE_INVALID });
  }, [id]);

  const getRedGifsRedGifsMovie = async (id) => {
    try {
      dispatch({ type: RED_GIFS_MOVIE_LOADING });
      const data = await api.get(`${RED_GIFS_MOVIES_URL}/${id}`);

      dispatch({ type: RED_GIFS_MOVIE_SUCCESS, payload: { data } });
    } catch (error) {
      notification.error({
        message: "Wystąpił błąd podczas pobierania filmu",
      });
      dispatch({ type: RED_GIFS_MOVIE_FAILURE, payload: { error } });
    }
  };

  const createRedGifsMovie = async ({ red_gifs_id }) => {
    try {
      dispatch({ type: RED_GIFS_MOVIE_SAVING });
      await api.post(`${RED_GIFS_MOVIES_URL}`, {
        red_gifs_id
      });

      notification.success({
        message: "RedGif został dodany",
      });

      navigate(`/red_gifs_movies?red_gifs_id=${red_gifs_id}`);
    } catch (error) {
      dispatch({ type: RED_GIFS_MOVIE_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas dodawania RedGif",
      });
    }
  };

  const value = useMemo(() => {
    return {
      state,
      createRedGifsMovie,
    };
  }, [state]);

  return (
    <RedGifsMovieContext.Provider value={value}>{children}</RedGifsMovieContext.Provider>
  );
}

const useRedGifsMovie = () => useContext(RedGifsMovieContext);
export { RedGifsMovieContext, useRedGifsMovie };
export default RedGifsMovieProvider;
