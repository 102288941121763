import React from "react";
import TagProvider from "context/providers/tag";
import TagEditForm from "./components/TagEditForm";

const TagEdit = () => (
  <TagProvider>
    <TagEditForm />
  </TagProvider>
);

export default TagEdit;
