export const USER_INVALID = "USER_INVALID";
export const USER_LOADING = "USER_LOADING";
export const USER_SAVING = "USER_SAVING";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";
export const USER_FAILURE_AFTER_SAVING = "USER_FAILURE_AFTER_SAVING";
export const USER_REMOVE_MOVIE = "USER_REMOVE_MOVIE";

export const initialState = {
  status: "invalid",
  data: null,
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_INVALID: {
      return initialState;
    }
    case USER_LOADING: {
      return {
        ...state,
        status: "loading",
      };
    }
    case USER_SAVING: {
      return {
        ...state,
        status: "saving",
      };
    }
    case USER_SUCCESS: {
      return {
        ...state,
        status: "success",
        data: payload.data,
      };
    }
    case USER_FAILURE: {
      return {
        ...state,
        status: "failure",
      };
    }
    case USER_FAILURE_AFTER_SAVING: {
      return {
        ...state,
        status: "error_after_saving",
      };
    }
    case USER_REMOVE_MOVIE: {
      return {
        ...state,
        data: {
          ...state.data,
          user_movies: [...state.data.user_movies].filter(
            ({ _id }) => _id !== payload.id
          ),
        },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
