import React from "react";
import MovieProvider from "context/providers/movie";
import MovieCreateForm from "./components/MovieCreateForm";

const MovieCreate = () => (
  <MovieProvider>
    <MovieCreateForm />
  </MovieProvider>
);

export default MovieCreate;
