import React from "react";
import { Typography, Layout, Row, Button } from "antd";
import { useAuth } from "context/providers/auth";

import { LogoutOutlined } from "@ant-design/icons";

const Header = () => {
  const { state, handleLogout } = useAuth();
  return (
    <Layout.Header className="ant-row ant-row-end ant-row-middle">
      <Row justify="end" align="middle">
        <Typography.Title
          level={5}
          style={{ color: "#fff", margin: 0 }}
        >
          {state?.user?.email}
        </Typography.Title>
        <Button
          className="m-l-20"
          type="primary"
          shape="round"
          icon={<LogoutOutlined />}
          onClick={handleLogout}
        >
          Wyloguj
        </Button>
      </Row>
    </Layout.Header>
  );
};

export default Header;
