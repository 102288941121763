import React from "react";
import MovieProvider from "context/providers/movie";
import MovieContainer from "./MovieContainer";

const UserEdit = () => (
  <MovieProvider>
    <MovieContainer />
  </MovieProvider>
);

export default UserEdit;
