import React from "react";
import SettingsProvider from "context/providers/settings";
import SettingsEditForm from "./components/SettingsEditForm";

const Settings = () => (
  <SettingsProvider>
    <SettingsEditForm />
  </SettingsProvider>
);

export default Settings;
