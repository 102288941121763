import React, { useState } from "react";
import {
  Card,
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  Upload,
  Checkbox,
  message,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { useAdvertise } from "context/providers/advertise";
import { useQuery } from "@tanstack/react-query";
import {
  api,
  TAGS_URL,
  UPLOAD_URL,
  USERS_URL,
} from "helpers/api";

let timeout;

const AdvertiseCreateForm = () => {
  const {
    state: { status },
    createAdvertise,
  } = useAdvertise();
  const [file, setFile] = useState(null);
  const [tag_value, setTagValue] = useState(null);
  const [user_value, setUserValue] = useState(null);

  const { data: tags } = useQuery(
    ["create_adds_tags_query", tag_value],
    () =>
      api.get(
        `${TAGS_URL}${
          tag_value ? `?name=${tag_value}` : ""
        }`
      )
  );
  const { data: users } = useQuery(
    ["create_tags_users_query", user_value],
    () =>
      api.get(
        `${USERS_URL}${
          user_value ? `?nickname=${user_value}` : ""
        }`
      )
  );

  const users_options =
    users?.data?.map(({ _id, nickname }) => (
      <Select.Option key={_id} value={_id}>
        {nickname}
      </Select.Option>
    )) || [];

  const tags_options =
    tags?.data?.map(({ _id, name }) => (
      <Select.Option key={_id} value={_id}>
        {name}
      </Select.Option>
    )) || [];

  const onSearchUser = (a) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setUserValue(a);
    }, 300);
  };

  const onSearchTag = (a) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setTagValue(a);
    }, 300);
  };

  const onFinish = (values) =>
    createAdvertise({ ...values, file });

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span="12">
        <Card>
          <Form
            name="advertise_create"
            initialValues={{
              is_active: true,
              advertise_url: "",
              description: "",
              user_id: "",
              tags: [],
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span="24">
                <Form.Item
                  label="Url reklamy"
                  name="advertise_url"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  label="Użytkownik"
                  name="user_id"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={"Szukaj..."}
                    showArrow={true}
                    filterOption={false}
                    onSearch={onSearchUser}
                    notFoundContent={null}
                  >
                    {users_options}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Tagi" name="tags">
                  <Select
                    mode="tags"
                    showSearch
                    placeholder={"Szukaj..."}
                    showArrow={true}
                    filterOption={false}
                    onSearch={onSearchTag}
                    notFoundContent={[]}
                  >
                    {tags_options}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item label="Opis" name="description">
                  <Input.TextArea />
                </Form.Item>
              </Col>
              <Col span="12">
                <Form.Item
                  name="is_active"
                  valuePropName="checked"
                >
                  <Checkbox>Aktywna</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button
                disabled={!file}
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zapisz
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span="12">
        <Upload.Dragger
          maxCount={1}
          style={{ padding: 20 }}
          name="file"
          action={UPLOAD_URL}
          onChange={(info) => {
            const { status, name, response } = info.file;
            if (status === "done") {
              message.success(
                `${name} plik został wysłany.`
              );
              setFile(response);
            } else if (status === "error") {
              message.error(`${name} file upload failed.`);
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Kliknij lub upuść tutaj plik video
          </p>
        </Upload.Dragger>
      </Col>
    </Row>
  );
};

export default AdvertiseCreateForm;
