import React from "react";
import { Card, Statistic, Row, Col } from "antd";

import { useMovie } from "context/providers/movie";
import convertDate from "helpers/convertDate";

const MovieInfo = () => {
  const {
    state: { data },
  } = useMovie();

  return (
    <Card className="m-b-15">
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <Statistic
            title="Czas trwania"
            value={data?.duration}
            precision={2}
          />
        </Col>
        <Col span={8}>
          <Statistic title="Polubienia" value={data?.likes_count} />
        </Col>
        <Col span={8}>
          <Statistic title="Wyświetlenia" value={data?.views_count} />
        </Col>
        <Col span={8}>
          <Statistic
            title="Data utworzenia"
            value={convertDate(data?.created_at)}
          />
        </Col>
        <Col span={8}>
          <Statistic title="Data ostatniej aktualizacji" value={convertDate(data?.updated_at)} />
        </Col>
      </Row>
    </Card>
  );
};

export default MovieInfo;
