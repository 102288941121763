import React, { useMemo, useReducer, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";

import { api, TAGS_URL } from "helpers/api";

import reducer, {
  initialState,
  TAG_INVALID,
  TAG_LOADING,
  TAG_SAVING,
  TAG_SUCCESS,
  TAG_FAILURE,
  TAG_FAILURE_AFTER_SAVING,
} from "../reducers/tag";

const TagContext = React.createContext();

function TagProvider({ children }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (id && id !== "new") {
      getTag(id);
    }
    return () => dispatch({ type: TAG_INVALID });
  }, [id]);

  const getTag = async (id) => {
    try {
      dispatch({ type: TAG_LOADING });
      const data = await api.get(`${TAGS_URL}/${id}`);

      dispatch({ type: TAG_SUCCESS, payload: { data } });
    } catch (error) {
      notification.error({
        message: "Wystąpił błąd podczas pobierania taga",
      });
      dispatch({ type: TAG_FAILURE, payload: { error } });
    }
  };

  const createTag = async ({ name }) => {
    try {
      dispatch({ type: TAG_SAVING });
      const { _id } = await api.post(`${TAGS_URL}`, { name });

      notification.success({
        message: "Tag został dodany",
      });
      navigate(`/tags/${_id}`);
    } catch (error) {
      dispatch({ type: TAG_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas dodawania taga",
      });
    }
  };

  const updateTag = async ({ name }) => {
    try {
      dispatch({ type: TAG_SAVING });
      const data = await api.put(`${TAGS_URL}/${id}`, { name });

      dispatch({ type: TAG_SUCCESS, payload: { data } });
      notification.success({
        message: "Tag został zaktualizowany",
      });
    } catch (error) {
      dispatch({ type: TAG_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas aktualizowania taga",
      });
    }
  };

  const deleteTag = () =>
    new Promise(async (resolve, reject) => {
      try {
        await api.delete(`${TAGS_URL}/${id}`);
        notification.success({
          message: "Tag został usunięty",
        });
        resolve();
        navigate("/tags");
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas usuwania taga",
        });
        reject();
      }
    });

  const value = useMemo(() => {
    return {
      state,
      updateTag,
      deleteTag,
      createTag,
    };
  }, [state]);

  return <TagContext.Provider value={value}>{children}</TagContext.Provider>;
}

const useTag = () => useContext(TagContext);
export { TagContext, useTag };
export default TagProvider;
