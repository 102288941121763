import React from "react";
import { Card, Button, Form, Input, Row, Col, Popconfirm } from "antd";

import { useTag } from "context/providers/tag";

import PageLoader from "components/PageLoader";
import PageFailure from "components/PageFailure";

const TagEditForm = () => {
  const {
    state: { status, data },
    updateTag,
    deleteTag,
  } = useTag();

  if (status === "invalid" || status === "loading") {
    return <PageLoader />;
  }
  if (status === "failure") {
    return (
      <PageFailure
        path="/tags"
        title="Nie udało się pobrać taga."
        button_text="Wróć do listy tagów"
      />
    );
  }

  const onFinish = (values) => updateTag(values);

  return (
    <Row>
      <Col span="8">
        <Card>
          <Form
            name="tag_edit"
            initialValues={{
              name: data?.name,
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item label="Nazwa" name="name">
              <Input />
            </Form.Item>
            <Row>
              <Button
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zapisz
              </Button>

              <Popconfirm
                title={`Jesteś pewien, że chcesz usunąć tag "${data?.name}" i wszystkie jej powiązania?`}
                onConfirm={deleteTag}
                cancelText="Nie"
                okText="Tak"
              >
                <Button
                  danger
                  loading={status === "saving"}
                  type="primary"
                  htmlType="submit"
                >
                  Usuń
                </Button>
              </Popconfirm>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default TagEditForm;
