import "./styles.scss";

import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const PageLoader = () => (
  <div className="page-loader">
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: 100,
          }}
          spin
        />
      }
    />
  </div>
);

export default PageLoader;
