import React, { useState } from "react";
import { List, Card, Skeleton, Popconfirm, Button } from "antd";
import { useMovie } from "context/providers/movie";
import { Link } from "react-router-dom";
import AddTagModal from "./components/AddTagModal";

const TagsList = () => {
  const {
    state: { status, data },
    deleteMovieTag,
  } = useMovie();
  const [is_open, setOpen] = useState(false);

  return (
    <Card className="m-t-15" title={`Tagi (${data?.tags?.length || 0})`}
      extra={
        <Button type="primary" onClick={() => setOpen(true)}>
          Dodaj
        </Button>
      }>
      <AddTagModal is_open={is_open} handleClose={() => setOpen(false)} />
      <List
        loading={status === "invalid" || status === "loading"}
        itemLayout="horizontal"
        dataSource={data?.tags || []}
        renderItem={({ _id, name }) => (
          <List.Item
            actions={[
              <Link to={`/tags/${_id}`} key="edit-tag">
                Edytuj
              </Link>,
              <Popconfirm
                title={`Jesteś pewien, że chcesz usunąć taga "${name}" dla tego filmu?`}
                onConfirm={() => deleteMovieTag(_id)}
                cancelText="Nie"
                okText="Tak"
              >
                <Button
                  danger
                  loading={status === "saving"}
                  type="primary"
                  htmlType="submit"
                >
                  Usuń
                </Button>
              </Popconfirm>,
            ]}
          >
            <Skeleton
              avatar
              title={false}
              loading={status === "invalid" || status === "loading"}
              active
            >
              <List.Item.Meta title={name} description={_id} />
            </Skeleton>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default TagsList;
