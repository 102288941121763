import React from "react";
import {
  Card,
  notification,
  Col,
  Row,
  Statistic,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { api, STATISTICS_URL } from "helpers/api";
import Loader from "components/Loader";

const Dashboard = () => {
  const { isLoading: is_loading, data } = useQuery(
    ["stats"],
    () => api.get(STATISTICS_URL),
    {
      retry: false,
      keepPreviousData: true,
      onError: (err) =>
        notification.error({
          message:
            err?.response?.data?.message || "Wystąpił błąd",
        }),
    }
  );
  
  if (is_loading) {
    return <Loader />;
  }

  return (
    <>
      <Card>
        <Row style={{ marginBottom: 20 }}>
          <Col span={6}>
            <Statistic
              title="Użytkownicy (wszyscy)"
              value={data?.users?.all}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Użytkownicy (gość)"
              value={data?.users?.guest}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Użytkownicy (user)"
              value={data?.users?.user}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Użytkownicy (virtual)"
              value={data?.users?.virtual}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Statistic title="Filmy" value={data?.movies} />
          </Col>
          <Col span={6}>
            <Statistic title="Tagi" value={data?.tags} />
          </Col>
          <Col span={6}>
            <Statistic
              title="Ilość pobrań aplikacji"
              value={data?.users?.downloaded_app}
            />
          </Col>
          <Col span={6}>
            <Statistic
              title="Ilość unikalnych IP"
              value={data?.users?.unique_ips}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Dashboard;
