import React from "react";
import AdvertiseProvider from "context/providers/advertise";
import AdvertiseCreateForm from "./components/AdvertiseCreateForm";

const AdvertiseCreate = () => (
  <AdvertiseProvider>
    <AdvertiseCreateForm />
  </AdvertiseProvider>
);

export default AdvertiseCreate;
