import React from "react";
import { Avatar, List, Skeleton, Card, Popconfirm, Button } from "antd";
import { Link } from "react-router-dom";
import { useUser } from "context/providers/user";
import convertDate from "helpers/convertDate";

const MoviesList = () => {
  const {
    state: { status, data },
    deleteUserMovie,
  } = useUser();

  return (
    <Card
      className="m-t-15"
      title={`Filmy użytkownika (${data?.user_movies?.length || 0})`}
    >
      <List
        loading={status === "invalid" || status === "loading"}
        itemLayout="horizontal"
        dataSource={data?.user_movies || []}
        renderItem={({ _id, tags, urls, created_at, title }) => (
          <List.Item
            actions={[
              <Link to={`/movies/${_id}`} key="edit-movie">
                Edytuj
              </Link>,
              <Popconfirm
                title={`Jesteś pewien, że chcesz usunąć film "${_id}"?`}
                onConfirm={() => deleteUserMovie(_id)}
                cancelText="Nie"
                okText="Tak"
              >
                <Button
                  danger
                  loading={status === "saving"}
                  type="primary"
                  htmlType="submit"
                >
                  Usuń
                </Button>
              </Popconfirm>,
            ]}
          >
            <Skeleton
              avatar
              title={false}
              loading={status === "invalid" || status === "loading"}
              active
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    shape="square"
                    size={40}
                    src={urls?.poster}
                  />
                }
                title={`${title} (${_id})`}
                description={tags?.map(({ name }) => name)?.join(", ")}
              />
              <span>{convertDate(created_at)}</span>
            </Skeleton>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default MoviesList;
