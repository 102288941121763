import React from "react";
import { Card, Button, Form, Input, Row, Col } from "antd";

import { useTag } from "context/providers/tag";

const TagCreateForm = () => {
  const {
    state: { status },
    createTag,
  } = useTag();

  const onFinish = (values) => createTag(values);

  return (
    <Row>
      <Col span="8">
        <Card>
          <Form
            name="tag_create"
            initialValues={{
              name: "",
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item label="Nazwa" name="name">
              <Input />
            </Form.Item>
            <Row>
              <Button
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zapisz
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default TagCreateForm;
