import React from "react";
import { Card, Button, Form, Input, Row, Col, Select } from "antd";

import { useUser } from "context/providers/user";

import user_type_options from "helpers/user_type_options";


const UserCreateForm = () => {
  const {
    state: { status },
    createUser,
  } = useUser();

  const onFinish = (values) => createUser(values);

  return (
    <Row>
      <Col span="16">
        <Card>
          <Form
            name="user_create"
            initialValues={{
              first_name: "",
              last_name: "",
              nickname: "",
              email: "",
              password: "",
              user_type: "user",
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span="8">
                <Form.Item label="Imię" name="first_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Nazwisko" name="last_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  label="Nick"
                  name="nickname"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span="8">
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  label="Hasło"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item
                  label="Typ użytkownika"
                  name="user_type"
                  rules={[
                    {
                      required: true,
                      message: "Pole wymagane",
                    },
                  ]}
                >
                  <Select style={{ width: 120 }}>
                    {user_type_options?.map(({ label, value }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zapisz
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default UserCreateForm;
