import React from "react";
import {
  notification,
  Button,
  Pagination,
  Form,
  Input,
  Row,
  Table,
  Select,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useQueryParams, NumberParam, StringParam } from "use-query-params";
import { useQuery } from "@tanstack/react-query";
import { api, RED_GIFS_MOVIES_URL } from "helpers/api";
import { stringify } from "query-string";
import columns from "./const/columns";

const RedGifsList = () => {
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    page_size: NumberParam,
    sort_column: StringParam,
    sort_order: StringParam,
    red_gifs_id: StringParam,
    user_name: StringParam,
    is_scrapped: StringParam,
    is_deleted: StringParam,
  });
  const navigate = useNavigate();
  const query_string = stringify(query);

  const { isLoading: is_loading, data } = useQuery(
    ["red_gifs_movies", query],
    () => api.get(`${RED_GIFS_MOVIES_URL}?${query_string}`),
    {
      retry: false,
      keepPreviousData: true,
      onError: (err) =>
        notification.error({
          message: err?.response?.data?.message || "Wystąpił błąd",
        }),
    }
  );

  const onTableDataChange = (pagination, filters, { field, order }) => {
    if (Boolean(order)) {
      setQuery({
        ...query,
        page: undefined,
        sort_order: order,
        sort_column: field,
      });
    } else {
      setQuery({
        ...query,
        page: undefined,
        sort_column: undefined,
        sort_order: undefined,
      });
    }
  };
  const [form] = Form.useForm();

  const onFilter = async (values) =>
    setQuery({
      ...query,
      page: undefined,
      sort_column: undefined,
      sort_order: undefined,
      red_gifs_id: values?.red_gifs_id || undefined,
      user_name: values?.user_name || undefined,
      is_scrapped: values?.is_scrapped || undefined,
      is_deleted: values?.is_deleted || undefined,
    });

  const onReset = async () => {
    form.resetFields();
    setQuery({
      ...query,
      page: undefined,
      red_gifs_id: undefined,
      user_name: undefined,
      is_scrapped: undefined,
      is_deleted: undefined,
    });
  };

  return (
    <>
      <Row
        justify="space-between"
        align="middle"
        style={{ paddingBottom: "20px" }}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            red_gifs_id: query?.red_gifs_id || "",
            user_name: query?.user_name || "",
            is_scrapped: query?.is_scrapped || "",
            is_deleted: query?.is_deleted || "",
          }}
          onFinish={onFilter}
          autoComplete="off"
          layout="inline"
        >
          <Form.Item label="Redgifs ID" name="red_gifs_id">
            <Input />
          </Form.Item>
          <Form.Item label="Użytkownik" name="user_name">
            <Input />
          </Form.Item>
          <Form.Item label="Pobrane" name="is_scrapped">
            <Select style={{ minWidth: 120 }}>
              <Select.Option value="">Wszystkie</Select.Option>
              <Select.Option value="true">Tak</Select.Option>
              <Select.Option value="false">Nie</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Usunięte" name="is_deleted">
            <Select style={{ minWidth: 120 }}>
              <Select.Option value="">Wszystkie</Select.Option>
              <Select.Option value="true">Tak</Select.Option>
              <Select.Option value="false">Nie</Select.Option>
            </Select>
          </Form.Item>
          <Button loading={is_loading} type="primary" htmlType="submit">
            Filtruj
          </Button>
          <Button
            className="m-l-20"
            type="danger"
            htmlType="button"
            onClick={onReset}
          >
            Resetuj
          </Button>
        </Form>
        <Button onClick={() => navigate("/red_gifs_movies/new")}>Dodaj RedGif</Button>
      </Row>
      <Table
        loading={is_loading}
        columns={columns}
        total={data?.meta?.limit}
        dataSource={data?.data?.map((item) => ({ ...item, key: item._id }))}
        onChange={onTableDataChange}
        pagination={false}
      />
      <Row justify="center" align="middle" style={{ padding: "20px 0" }}>
        <Pagination
          {...{
            current: query?.page || 1,
            defaultPageSize: 15,
            pageSize: query?.page_size || 15,
            total: data?.meta?.totalDocs,
            pageSizeOptions: [15, 30, 50],
            showSizeChanger: true,
            onChange: (page, page_size) =>
              setQuery({ ...query, page, page_size }),
          }}
        />
      </Row>
    </>
  );
};

export default RedGifsList;
