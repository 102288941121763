import React, { useMemo, useReducer, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { notification } from "antd";

import { api, USERS_URL, MOVIES_URL } from "helpers/api";

import reducer, {
  initialState,
  USER_INVALID,
  USER_LOADING,
  USER_SAVING,
  USER_SUCCESS,
  USER_FAILURE,
  USER_FAILURE_AFTER_SAVING,
  USER_REMOVE_MOVIE
} from "../reducers/user";

const UserContext = React.createContext();

function UserProvider({ children }) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (id && id !== "new") {
      getUser(id);
    }
    return () => dispatch({ type: USER_INVALID });
  }, [id]);

  const getUser = async (id) => {
    try {
      dispatch({ type: USER_LOADING });
      const data = await api.get(`${USERS_URL}/${id}`);

      dispatch({ type: USER_SUCCESS, payload: { data } });
    } catch (error) {
      notification.error({
        message: "Wystąpił błąd podczas pobierania użytkownika",
      });
      dispatch({ type: USER_FAILURE, payload: { error } });
    }
  };

  const createUser = async ({
    first_name,
    last_name,
    email,
    password,
    nickname,
    user_type,
  }) => {
    try {
      dispatch({ type: USER_SAVING });
      const { id } = await api.post(`${USERS_URL}`, {
        first_name,
        last_name,
        email,
        password,
        nickname,
        user_type,
      });

      notification.success({
        message: "Użytkownik został dodany",
      });

      navigate(`/users/${id}`);
    } catch (error) {
      dispatch({ type: USER_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas dodawania użytkownika",
      });
    }
  };

  const updateUser = async ({
    first_name,
    last_name,
    email,
    nickname,
    user_type,
  }) => {
    try {
      dispatch({ type: USER_SAVING });
      const data = await api.put(`${USERS_URL}/${id}`, {
        first_name,
        last_name,
        email,
        nickname,
        user_type,
      });

      dispatch({ type: USER_SUCCESS, payload: { data } });
      notification.success({
        message: "Użytkownik został zaktualizowany",
      });
    } catch (error) {
      dispatch({ type: USER_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas aktualizowania użytkownika",
      });
    }
  };

  const changeUserPassword = async ({ password }) => {
    try {
      dispatch({ type: USER_SAVING });
      const { data } = await api.put(`${USERS_URL}/${id}/change_password`, {
        password,
      });

      dispatch({ type: USER_SUCCESS, payload: { data } });
      notification.success({
        message: "Hasło zostało zmienione",
      });
    } catch (error) {
      dispatch({ type: USER_FAILURE_AFTER_SAVING });
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas zmieniania hasła",
      });
    }
  };

  const deleteUser = () =>
    new Promise(async (resolve, reject) => {
      try {
        await api.delete(`${USERS_URL}/${id}`);
        notification.success({
          message: "Użytkownik został usunięty",
        });
        resolve();
        navigate("/users");
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas usuwania użytkownika",
        });
        reject();
      }
    });

  const deleteUserMovie = (id) =>
    new Promise(async (resolve, reject) => {
      try {
        await api.delete(`${MOVIES_URL}/${id}`);
        notification.success({
          message: "Film użytkownika został usunięty",
        });
        resolve();
        dispatch({ type: USER_REMOVE_MOVIE, payload: { id } });
      } catch (error) {
        notification.error({
          message:
            error?.response?.data?.message ||
            "Wystąpił błąd podczas usuwania filmu użytkownika",
        });
        reject();
      }
    });

  const value = useMemo(() => {
    return {
      state,
      updateUser,
      deleteUser,
      createUser,
      changeUserPassword,
      deleteUserMovie,
    };
  }, [state]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

const useUser = () => useContext(UserContext);
export { UserContext, useUser };
export default UserProvider;
