import "./styles/app.scss";

import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { CookiesProvider } from "react-cookie";
import { ConfigProvider } from "antd";
import en_GB from "antd/lib/locale-provider/en_GB";
import moment from "moment";
import "moment/locale/en-gb";
import AuthProvider from "context/providers/auth";
import { BrowserRouter } from "react-router-dom";

moment.locale("en-gb");
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <ConfigProvider locale={en_GB}>
            <CookiesProvider>
              <AuthProvider />
            </CookiesProvider>
          </ConfigProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
