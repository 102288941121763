import React from "react";
import { Card, Button, Form, Input, Row, Col, Popconfirm } from "antd";

import { useMovie } from "context/providers/movie";


const MovieEditForm = () => {
  const {
    state: { status, data },
    updateMovie,
    deleteMovie,
  } = useMovie();

  const onFinish = (values) => updateMovie(values);

  return (
    <Card>
      <Form
        name="movie_edit"
        initialValues={{
          title: data?.title,
          source_url: data?.source_url,
          description: data?.description,
        }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span="12">
            <Form.Item
              label="Tytuł"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Pole wymagane",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span="12">
            <Form.Item label="Źródło" name="source_url">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span="24">
            <Form.Item label="Opis" name="description">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Button
            className="m-r-20"
            loading={status === "saving"}
            type="primary"
            htmlType="submit"
          >
            Zapisz
          </Button>
          <Popconfirm
            title={`Jesteś pewien, że chcesz usunąć film "${data?.title}" i wszystkie jego filmy?`}
            onConfirm={deleteMovie}
            cancelText="Nie"
            okText="Tak"
          >
            <Button
              danger
              loading={status === "saving"}
              type="primary"
              htmlType="submit"
            >
              Usuń
            </Button>
          </Popconfirm>
        </Row>
      </Form>
    </Card>
  );
};

export default MovieEditForm;
