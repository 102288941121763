import React, { useState } from "react";
import { Button, Modal, Select } from "antd";
import { useQuery } from "@tanstack/react-query";
import { api, TAGS_URL } from "helpers/api";
import { useAdvertise } from "context/providers/advertise";

let timeout;

const AddTagModal = ({ is_open, handleClose }) => {
  const [is_loading, setLoading] = useState(false);
  const [tag_id, setTagId] = useState(null);
  const [value, setValue] = useState(null);
  const { createAdvertiseTag } = useAdvertise();
  const { data } = useQuery(
    ["add_tag_query", value],
    () => api.get(`${TAGS_URL}?name=${value}`),
    { enabled: value?.length > 1 }
  );
  const onSubmit = async () => {
    try {
      setLoading(true);
      await createAdvertiseTag(tag_id);
      handleClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const options =
    data?.data?.map(({ _id, name }) => (
      <Select.Option key={_id} value={`${_id}||${name}`}>
        {" "}
        {name}
      </Select.Option>
    )) || [];

  const onSearch = (a) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setValue(a);
    }, 300);
  };

  const onChange = (option) => {
    const val = option.split("||")[1];
    const id = option.split("||")[0];
    setValue(val);
    setTagId(id);
  };

  return (
    <Modal
      visible={is_open}
      title="Dodaj tag"
      onOk={onSubmit}
      onCancel={handleClose}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleClose}>
          Anuluj
        </Button>,
        <Button
          disabled={!tag_id}
          key="submit"
          type="primary"
          loading={is_loading}
          onClick={onSubmit}
        >
          Dodaj
        </Button>,
      ]}
    >
      <Select
        style={{ minWidth: 200 }}
        showSearch
        value={value}
        placeholder={"Szukaj..."}
        showArrow={true}
        filterOption={false}
        onSearch={onSearch}
        onChange={onChange}
        notFoundContent={null}
      >
        {options}
      </Select>
    </Modal>
  );
};

export default AddTagModal;
