import React from "react";
import UserProvider from "context/providers/user";
import TagCreateForm from "./components/UserCreateForm";

const UserCreate = () => (
  <UserProvider>
    <TagCreateForm />
  </UserProvider>
);

export default UserCreate;
