import React from "react";
import AdvertiseProvider from "context/providers/advertise";
import AdvertiseContainer from "./AdvertiseContainer";

const AdvertiseEdit = () => (
  <AdvertiseProvider>
    <AdvertiseContainer />
  </AdvertiseProvider>
);

export default AdvertiseEdit;
