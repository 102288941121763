import React, { useState } from 'react'
import { Button, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { api, MOVIES_URL } from "helpers/api";
import { useQueryClient } from '@tanstack/react-query';

const DownloadMovie = ({ id }) => {
  const [is_loading, setLoading] = useState(false);
  const query_client = useQueryClient()
  const handleDownload = async () => {
    try {
      setLoading(true)
      await api.post(`${MOVIES_URL}/red_gifs_movie`, {
        red_gifs_id: id
      })
      notification.success({
        message: "Film został dodany",
      });
    } catch (error) {
      notification.error({
        message:
          error?.response?.data?.message ||
          "Wystąpił błąd podczas dodawania filmu",
      });
    } finally {
      setLoading(false)
      query_client.refetchQueries(['red_gifs_movies'])
    }
  }
  return (
    <Button
      loading={is_loading}
      onClick={handleDownload}
      type="primary"
      shape="circle"
      icon={<DownloadOutlined />}
    />);
}

export default DownloadMovie;