import React, { useState } from "react";
import { Card, Button, Form, Input, Row, Col, Select, Upload, message } from "antd";
import { InboxOutlined } from '@ant-design/icons';

import { useMovie } from "context/providers/movie";
import { useQuery } from "@tanstack/react-query";
import { api, TAGS_URL, UPLOAD_URL, USERS_URL } from "helpers/api";

let timeout

const MovieCreateForm = () => {
  const {
    state: { status },
    createMovie,
  } = useMovie();
  const [file, setFile] = useState(null)
  const [tag_value, setTagValue] = useState(null)
  const [user_value, setUserValue] = useState(null)

  const { data: tags } = useQuery(['create_movie_tags_query', tag_value], () => api.get(`${TAGS_URL}${tag_value ? `?name=${tag_value}` : ''}`))
  const { data: users } = useQuery(['create_movie_users_query', user_value], () => api.get(`${USERS_URL}${user_value ? `?nickname=${user_value}` : ''}`))

  const users_options = users?.data?.map(({ _id, nickname }) => <Select.Option key={_id} value={_id}>{nickname}</Select.Option >) || [];
  const tags_options = tags?.data?.map(({ _id, name }) => <Select.Option key={_id} value={_id}>{name}</Select.Option >) || [];

  const onSearchUser = (a) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setUserValue(a)
    }, 300);
  }

  const onSearchTag = (a) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      setTagValue(a)
    }, 300);
  }

  const onFinish = (values) => createMovie({ ...values, file });

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span="16">
        <Card>
          <Form
            name="movie_create"
            initialValues={{
              title: "",
              description: "",
              user_id: "",
              tags: [],
            }}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span="8">
                <Form.Item label="Tytuł" name="title" rules={[
                  {
                    required: true,
                    message: "Pole wymagane",
                  },
                ]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span="8">
                <Form.Item label="Użytkownik" name="user_id" rules={[
                  {
                    required: true,
                    message: "Pole wymagane",
                  },
                ]}>
                  <Select
                    showSearch
                    placeholder={'Szukaj...'}
                    showArrow={true}
                    filterOption={false}
                    onSearch={onSearchUser}
                    notFoundContent={null}
                  >
                    {users_options}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item
                  label="Tagi"
                  name="tags"
                >
                  <Select
                    mode="tags"
                    showSearch
                    placeholder={'Szukaj...'}
                    showArrow={true}
                    filterOption={false}
                    onSearch={onSearchTag}
                    notFoundContent={[]}
                  >
                    {tags_options}
                  </Select>
                </Form.Item>
              </Col>
              <Col span="24">
                <Form.Item
                  label="Opis"
                  name="description"
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Button
                disabled={!file}
                className="m-r-20"
                loading={status === "saving"}
                type="primary"
                htmlType="submit"
              >
                Zapisz
              </Button>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span="8">
        <Upload.Dragger
          maxCount={1}
          style={{ padding: 20 }}
          name="file"
          action={UPLOAD_URL}
          onChange={(info) => {
            const { status, name, response } = info.file;
            if (status === 'done') {
              message.success(`${name} plik został wysłany.`);
              console.log(response)
              setFile(response)
            } else if (status === 'error') {
              message.error(`${name} file upload failed.`);
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Kliknij lub upuść tutaj plik video</p>
        </Upload.Dragger>
      </Col>
    </Row>
  );
};

export default MovieCreateForm;
